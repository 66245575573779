const PX = 'px';
const SIZE = 100;
let gameContainer;
let person;
// 计算列数和行数
let numColumns;
let numRows;
let realWidth;
let realHeight;
let xySet = new Set();

//todo 添加游戏开始界面 点击开始后初始化游戏界面

//todo 添加游戏倒计时

//todo 添加游戏结束界面

  document.addEventListener('DOMContentLoaded', function () {
    // 获取按钮元素
    let myButton = document.getElementById("startButton");
    //显示开始界面
    let bigContainer = document.getElementById('big-container');
    bigContainer.style.justifyContent = 'center';

    // 添加点击事件监听器
    myButton.addEventListener("click", function() {
      if (isMobile()) {
        hiddenStartContainer();
        initAll();
        showVirtualKeyboard();
        mobilePlayer();
      } else {
        initAll();
        pcPlayer();
      }
      // 生成随机位置的汉字
      createText();
    });

  });

  function initGameSize() {
    // 获取屏幕宽度和高度
    let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    // 计算 #game-container 的宽度和高度
    let containerWidth = Math.floor(screenWidth / SIZE) * SIZE;
    let innerRealWidth = containerWidth >= 500 ? 500 : containerWidth;

    let containerHeight = Math.floor(screenHeight / SIZE) * SIZE;
    let innerRealHeight = containerHeight >= 500 ? 500 : containerHeight;

    // 设置 #game-container 的宽度和高度
    let gameContainer = document.getElementById('game-container');
    gameContainer.style.display = 'block';
    gameContainer.style.width = `${innerRealWidth}px`;
    gameContainer.style.height = `${innerRealHeight}px`;

    return gameContainer;
  }

  function initPerson() {
    let person = document.createElement('div');
    person.className = 'person';
    gameContainer.appendChild(person);
    // 随机生成小人的初始位置
    let xy = randomXY();
    person.style.left = xy.getX + PX;
    person.style.top = xy.getY + PX;
    person.row = xy.getX / SIZE;
    person.column = xy.getY / SIZE;

    return person;
  }


  function pcPlayer() {
    document.addEventListener('keydown', function (event) {
      let personRect = person.getBoundingClientRect();
      switch (event.key) {
        case 'ArrowUp':
          movePerson(person.column, Math.max(person.row - 1, 0));
          break;
        case 'ArrowDown':
          movePerson(person.column, Math.min(person.row + 1, numRows - 1));
          break;
        case 'ArrowLeft':
          movePerson(Math.max(person.column - 1, 0), person.row);
          break;
        case 'ArrowRight':
          movePerson(Math.min(person.column + 1, numColumns - 1), person.row);
          break;
      }

      //碰撞检查
      // collisionCheck(personRect);

    });
  }

  function mobilePlayer() {
    //
    // let keyBoardContainer = document.getElementById('key-board');
    //
    // let mc = new Hammer(keyBoardContainer);




    let keyUp = document.getElementById('key-up');
    let keyLeft = document.getElementById('key-left');
    let keyRight = document.getElementById('key-right');
    let keyDown = document.getElementById('key-down');

    let hammerUp = new Hammer(keyUp);
    let hammerLeft = new Hammer(keyLeft);
    let hammerRight = new Hammer(keyRight);
    let hammerDown = new Hammer(keyDown);

    hammerUp.on('tap', () => {
      movePerson(person.column, Math.max(person.row - 1, 0));
    });
    hammerDown.on('tap', () => {
      movePerson(person.column, Math.min(person.row + 1, numRows - 1));
    });
    hammerLeft.on('tap', () => {
      movePerson(Math.max(person.column - 1, 0), person.row);
    });
    hammerRight.on('tap', () => {
      movePerson(Math.min(person.column + 1, numColumns - 1), person.row);
    });

  }

  function movePerson(column, row) {
    person.style.left = column * SIZE + PX;
    person.style.top = row * SIZE + PX;
    person.column = column;
    person.row = row;
    //玩家移动音效
    playMoveSound();

    let personRect = person.getBoundingClientRect();
    //碰撞检查
    collisionCheck(personRect);
  }

  //碰撞检查
  function collisionCheck(personRect) {
    let chineseCharacters = Array.from(document.querySelectorAll('.chinese-character'));

    chineseCharacters.forEach(character => {
      let characterRect = character.getBoundingClientRect();
      let across = isColliding(personRect, characterRect);
      if (across) {
        playPengZhuangSound();
        // 移除已经碰到的汉字
        character.innerText = "";
        character.remove();
        let x = character.style.left;
        let y = character.style.top;
        let xy = x.replace(PX, "") + "_" + y.replace(PX, "");
        if (xySet.has(xy)) {
          xySet.delete(xy);
        }

        // 播放汉字读音
        playChineseCharacterSound(character.innerText);

      }
    });
  }

  //人物移动的音效
  function playMoveSound() {
    let moveSound = document.getElementById('move-sound');
    moveSound.currentTime = 0;
    moveSound.play();
  }

  //人物与汉字相遇的音效
  function playPengZhuangSound() {
    let pengZhuangtSound = document.getElementById('peng-zhuang-sound');
    pengZhuangtSound.currentTime = 0;
    pengZhuangtSound.play();
  }

  function playChineseCharacterSound(character) {
    // 使用中文语音合成API播放汉字读音
    // 请根据具体的语音合成API文档进行实现
    console.log('Playing sound for: ' + character);
    // 示例：百度语音合成API
    // const audio = new Audio(`http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&text=${character}`);
    // audio.play();
  }

  function generateRandomChineseCharacter() {
    // 生成随机汉字的逻辑，可以根据需要调整
    let randomCharCode = Math.floor(Math.random() * (0x9FA5 - 0x4E00) + 0x4E00);
    return String.fromCharCode(randomCharCode);
  }

  function generateRandomNumberCharacter() {
    return Math.floor(Math.random() * 21);
  }

  function isColliding(rect1, rect2) {
    return (
      rect1.left < rect2.right &&
      rect1.right > rect2.left &&
      rect1.top < rect2.bottom &&
      rect1.bottom > rect2.top
    );
  }

  function randomXY() {
    let randomMaxW = gameContainer.offsetWidth / SIZE;
    let randomMaxH = gameContainer.offsetHeight / SIZE;

    let randomCol = Math.floor(Math.random() * randomMaxW);
    let randomRow = Math.floor(Math.random() * randomMaxH);

    let x = randomCol * SIZE;
    let y = randomRow * SIZE;
    return {getX : x, getY : y}
  }

  function createText() {
    setInterval(() => {
      let chineseCharacter = document.createElement('div');

      chineseCharacter.className = 'chinese-character';
      chineseCharacter.innerText = generateRandomNumberCharacter();

      let characterXY = randomXY();
      let x = characterXY.getX;
      let y = characterXY.getY;
      let xy = x + "_" + y;

      //判断坐标下是否已经存在了汉字 如果已经有则不再添加
      let flag = !xySet.has(xy) && x < realWidth && y < realHeight;
      if (flag) {
        chineseCharacter.style.left = x + PX;
        chineseCharacter.style.top = y + PX;

        gameContainer.appendChild(chineseCharacter);
        xySet.add(xy);
      }
    }, 2000);
  }

  function isMobile() {
    // 使用正则表达式检测用户代理字符串中是否包含移动设备的标志
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  //判断虚拟键盘是否显示  移动端显示  PC端不显示
  function showVirtualKeyboard() {
    let bigContainer = document.getElementById('big-container');
    bigContainer.style.justifyContent = 'center';

    let gameContainer = document.getElementById('game-container');
    gameContainer.style.marginTop = '0px';

    let keyBoardContainer = document.getElementById('key-board');
    keyBoardContainer.style.display = 'grid'
  }

  function initAll() {
    gameContainer = initGameSize();
    person = initPerson();
    // 计算列数和行数
    numColumns = Math.floor(gameContainer.offsetWidth / SIZE);
    numRows = Math.floor(gameContainer.offsetHeight / SIZE);
    realWidth = Math.floor(gameContainer.offsetWidth / SIZE) * SIZE;
    realHeight = Math.floor(gameContainer.offsetHeight / SIZE) *SIZE;
  }

  function hiddenStartContainer() {
    let startContainer = document.getElementById('start-container');
    startContainer.remove();
  }

